import axios from 'axios'
import {useState} from 'react'
import {FileUploader} from 'react-drag-drop-files'
import FormatNumber from '../../components/FormatNumber'
import {BulkWithdrawalUpload_ExportExcel} from './BulkWithdrawalUpload_ExportCSV'

export default function Bulk_Withdrawal_Upload(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  const spinnerStyle = {
    border: '4px solid rgba(0, 0, 0, 0.3)',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    animation: 'spin 1s linear infinite',
    margin: 'auto',
  }

  //States
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<any>(null)
  const [response, setResponse] = useState<any>({
    result: {
      successCount: 0,
      failCount: 0,
      details: [
        {
          msg: '',
          data: {
            TransactionTimeStamp: '',
            DestinationAccountName: '',
            Reference: '',
            CustomerCode: '',
            ResponseURL: '',
            Amount: '',
            BankCode: '',
            MerchantTransID: '',
            ClientIP: '',
            ResultURL: '',
            WalletAccountNo: '',
            MerchantCode: '',
            Currency: '',
            Signature: '',
            DestinationAccountNo: '',
          },
          row: 0,
          status: '',
        },
      ],
    },
    code: '200',
    message: 'Process Completed!',
    status: 'Success!',
  })
  const [filteredResponse, setFilteredResponse] = useState<any>(null)
  const [selectedStatus, setSelectedStatus] = useState('Total')

  //Functions
  const handleChange = (file: any) => {
    setFile(file)
  }

  const fileTypes = ['csv', 'xls', 'xlsx']

  function uploadCSV() {
    if (file == null) {
      alert('No file uploaded yet.')
      return
    }

    setLoading(true)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('session', session)
    formData.append('isCreatedBulk', 'true')
    formData.append('isCreatedBulk', 'true')

    axios
      .post(`${SISPAY_API}/cash/withdrawalrequest/admin/create/bulk`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        setResponse(response.data)
        setFile(null)
        setLoading(false)
      })
      .catch(function (error) {
        alert(error)
      })
  }

  function filterBy(status: string) {
    const data = response.result.details
    if (status === '') {
      setFilteredResponse(null)
      setResponse(response)
    } else {
      const filteredData = data.filter((item: any) => item.status === status)
      setFilteredResponse({
        result: {
          successCount: response.result['successCount:'],
          failCount: response.result['failCount'],
          details: filteredData,
        },
        code: '200',
        message: 'Process Completed!',
        status: 'Success!',
      })
    }
  }

  function renderTableCell() {
    let tableCell
    let keyword = filteredResponse !== null ? filteredResponse : response

    tableCell = keyword.result.details.map((item: any, index: number) => {
      return (
        <tr key={index}>
          <td>{item.row}</td>
          <td
            style={{
              color: item.status == 'Failed' ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {item.status.toUpperCase()}
          </td>
          <td>{item.msg}</td>
          <td>{item.data.MerchantCode}</td>
          <td>{item.data.CustomerCode}</td>
          <td>{item.data.Currency}</td>
          <td>{item.data.BankCode}</td>
          <td>{FormatNumber(Number(item.data.Amount))}</td>
          <td>{item.data.MerchantTransID}</td>
          <td>{item.data.Reference}</td>
          <td>{item.data.TransactionTimeStamp}</td>
          <td>{item.data.ClientIP}</td>
          <td>{item.data.ResultURL}</td>
          <td>{item.data.ResponseURL}</td>
          <td>{item.data.DestinationAccountNo}</td>
          <td>{item.data.DestinationAccountName}</td>
          <td>{item.data.WalletAccountNo}</td>
        </tr>
      )
    })

    return tableCell
  }

  return (
    <div>
      <div
        className='modal fade'
        id='kt_modal_bulk_withdrawal_upload'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                Bulk Withdrawal Upload
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  setFile(null)
                }}
              ></button>
            </div>
            <div className='modal-body '>
              <FileUploader
                handleChange={handleChange}
                name='bulk_withdrawal_upload'
                types={fileTypes}
                multiple={false}
                fileOrFiles={file}
              />
              <div className='mt-3'>
                {file ? (
                  <div className='text-success'>File name: {file.name}</div>
                ) : (
                  <div className='text-danger'>No files uploaded yet.</div>
                )}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
                onClick={() => {
                  setFile(null)
                }}
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-success'
                data-bs-target='#bulkWithdrawUploadModal'
                data-bs-toggle='modal'
                disabled={file == null}
                onClick={() => {
                  uploadCSV()
                }}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='bulkWithdrawUploadModal'
        aria-hidden='true'
        aria-labelledby='bulkWithdrawUploadModal'
        tabIndex={-1}
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        <div className='modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='d-flex align-items-center'>
                <h1 className='modal-title fs-5 me-5' id='bulkWithdrawUploadModal'>
                  {loading ? `Processing Upload...` : `Process Completed!`}
                </h1>
                <span
                  className='me-5 badge badge-primary'
                  onClick={() => {
                    setSelectedStatus('Total')
                    setFilteredResponse(null)
                    filterBy('')
                  }}
                  style={{
                    cursor: 'pointer',
                    border: selectedStatus == 'Total' ? '3px dotted white' : 'none',
                  }}
                >
                  Total: {response.result.details.length}
                </span>
                <span
                  className='me-5 badge badge-success'
                  onClick={() => {
                    setSelectedStatus('Success')
                    filterBy('Success')
                  }}
                  style={{
                    cursor: 'pointer',
                    border: selectedStatus == 'Success' ? '3px dotted white' : 'none',
                  }}
                >
                  Success: {response.result['successCount:']}
                </span>
                <span
                  className='badge badge-danger'
                  onClick={() => {
                    setSelectedStatus('Fail')
                    filterBy('Failed')
                  }}
                  style={{
                    cursor: 'pointer',
                    border: selectedStatus == 'Fail' ? '3px dotted white' : 'none',
                  }}
                >
                  Fail: {response.result['failCount']}
                </span>
              </div>

              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                disabled={loading ? true : false}
              ></button>
            </div>
            <div className='modal-body'>
              <div className='h-100 w-100'>
                <div style={spinnerStyle} hidden={!loading ? true : false}></div>
              </div>
              <div className='table-responsive' hidden={loading ? true : false}>
                <table className='table table-rounded table-striped border gy-7 gs-7'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                      <th>Row</th>
                      <th>Status</th>
                      <th>Feedback</th>
                      <th>MerchantCode</th>
                      <th>CustomerCode</th>
                      <th>Currency</th>
                      <th>BankCode</th>
                      <th>Amount</th>
                      <th>MerchantTransID</th>
                      <th>Reference</th>
                      <th>TransactionTimeStamp</th>
                      <th>ClientIP</th>
                      <th>ResultURL</th>
                      <th>ResponseURL</th>
                      <th>DestinationAccountNo</th>
                      <th>DestinationAccountName</th>
                      <th>WalletAccountNo</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableCell()}</tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-info me-auto'
                disabled={loading ? true : false}
                onClick={() => BulkWithdrawalUpload_ExportExcel(response)}
              >
                Download Report
              </button>
              <button
                className='btn btn-secondary'
                data-bs-dismiss='modal'
                disabled={loading ? true : false}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
