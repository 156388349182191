import axios from 'axios'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {ErrorHandling} from '../../components/ErrorHandling'
import {useAuth} from '../../auth'

export default function CustCodeBlackListCreateModal(props: any) {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const {logout} = useAuth()

  let setLoading = props.setLoading
  let loading = props.loading

  const initialValues = {
    customerCd: '',
  }

  const filterSchema = Yup.object().shape({
    customerCd: Yup.string().required('Customer Code is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        axios
          .post(
            `${SISPAY_API}/blacklist/suspectedcustomer/create`,
            {
              request: {
                session: session,
                customerCodes: [values.customerCd],
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            if (response.data.code == 200) {
              setLoading(false)
              alert(response.data.message)
            } else {
              setLoading(false)
              ErrorHandling(response, logout)
            }
          })
          .catch(function (error) {
            alert(error)
          })
      } catch (error) {}
    },
  })

  return (
    <div
      className='modal fade'
      id='custCodeBlackListCreateModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='custCodeBlackListCreateModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <form onSubmit={formik.handleSubmit} className=''>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='custCodeBlackListCreateModalLabel'>
                Add New Customer Code Blacklist
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <div className='fv-row mb-8'>
                <label className='form-label required fw-bolder text-dark fs-6'>
                  Customer Code
                </label>
                <input
                  placeholder='Enter Customer Code'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('customerCd')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.customerCd && formik.errors.customerCd,
                    },
                    {
                      'is-valid': formik.touched.customerCd && !formik.errors.customerCd,
                    }
                  )}
                />
                {formik.touched.customerCd && formik.errors.customerCd && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.customerCd}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
