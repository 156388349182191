import {create} from 'zustand'

export const useWithdrawalPatchedStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    search: '',
    merchant: '',
    walletCd: '',
    withdrawalStatus: '',
    patchType: '',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
