import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {useAuth} from '../../../auth'
import {sortByName} from '../../../components/SortByName'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {filterArrayByMultipleKeys} from '../../../components/Filter'
import {searching} from '../../../components/Searching'
import {Overlay} from '../../../components/Overlay'
import {customStyles} from '../../../components/CustomStyles'
import {useWithdrawalPatchedStore} from './WithdrawalPatchedStore'
import {sortByDate} from '../../../components/SortByDate'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'

export function WithdrawalPatchedFilter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Zustand Store
  const filterOptions = useWithdrawalPatchedStore((state: any) => state.filterOptions)
  const getData = useWithdrawalPatchedStore((state: any) => state.getData)
  const setFilterOptions = useWithdrawalPatchedStore((state: any) => state.setFilterOptions)
  const setGetData = useWithdrawalPatchedStore((state: any) => state.setGetData)

  //Props from parent
  let setFormData = props.setFormData
  let selectedMerchantCd = props.selectedMerchantCd

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [merchantCdList, setMerchantCdList] = useState([])
  const [patchTypeList, setPatchTypeList] = useState([])

  //Ref
  const patchTypeSelectBtn = useRef<any>()

  const handlePatchType = (selected: any) => {
    if (selected !== '') {
      setFilterOptions({
        ...filterOptions,
        patchType: selected.map((option: any) => option.value).join(','),
      })
    } else {
      setFilterOptions({...filterOptions, patchType: ''})
    }
  }

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveMerchant(SISPAY_API: string, url: string, location: string) {
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response: any) {
        let result = response.data.result[`${location}`]
        if (response.data.code == '200') {
          let merchantCdList: any = Array.from(
            new Set(result.map((request: any) => request.merchantCd))
          )
            .filter((merchantCd) => merchantCd !== '')
            .map((request) => {
              return {value: request, label: request}
            })
          merchantCdList = sortByName(merchantCdList, 'value')
          setMerchantCdList(merchantCdList)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  function getPatchType(SISPAY_API: string) {
    axios
      .post(
        `${SISPAY_API}/patched/configtype/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response: any) {
        let result = response.data.result.patchTypeConfig
        if (response.data.code == '200') {
          let patchTypeList: any = result.map((item: any) => {
            return {value: item.patchCode, label: item.patchText}
          })
          patchTypeList = sortByName(patchTypeList, 'value')
          setPatchTypeList(patchTypeList)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  //GET USER LIST
  useEffect(() => {
    retrieveMerchant(SISPAY_API, '/merchant/list', 'merchant')
    getPatchType(SISPAY_API)
  }, [])

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    merchant: filterOptions.merchant ? String(filterOptions.merchant) : '',
    walletCd: filterOptions.walletCd ? String(filterOptions.walletCd) : '',
    withdrawalStatus: filterOptions.withdrawalStatus ? String(filterOptions.withdrawalStatus) : '',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
    patchType: filterOptions.patchType ? String(filterOptions.patchType) : '',
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string(),
  })

  //Search function: START
  const keys = ['customerCd', 'walletAccNo', 'submittedTxnId', 'merchantTxnId']

  //Search function: END

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)

      let search = values.search.trim()
      let merchant = values.merchant.trim()
      let walletCd = values.walletCd.trim()
      let withdrawalStatus = values.withdrawalStatus.trim()
      let dateTimeFrom = values.dateTimeFrom.trim()
      let dateTimeTo = values.dateTimeTo.trim()
      let patchType = filterOptions.patchType

      values.patchType = patchType

      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/patched/withdrawalrequest/list`,
          {
            request: {
              session: session,
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
              ...(patchType && {patchCode: patchType}),
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let result = response.data.result.patchedWithdrawalRequest

          let filteredFormData = filterArrayByMultipleKeys(
            result,
            ['merchantCd', 'walletCd', 'withdrawalStatus'],
            [merchant, walletCd, withdrawalStatus]
          )

          if (search !== '') {
            filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
          }
          //   setFormData(sortByName(filteredFormData, 'nickname'))
          setFormData(sortByDate(filteredFormData, 'withdrawalDT'))

          setLoading(false)
          setDisabled(false)
        })
        .catch(function (error) {})
    },
  })

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      {' '}
                      {/* begin::Form group Search */}
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>Search</label>
                        <span>
                          {Overlay(
                            'Search by Customer Code, Wallet Account No ,Submitted Txn ID ,Merchant Txn ID'
                          )}
                        </span>
                      </div>
                      <input
                        placeholder='Enter your search'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('search')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.search && formik.errors.search,
                          },
                          {
                            'is-valid': formik.touched.search && !formik.errors.search,
                          }
                        )}
                      />
                      {formik.touched.search && formik.errors.search && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.search}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                    <div className='col'>
                      {/* begin::Form group*/}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
                        <select
                          {...formik.getFieldProps('walletCd')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='NAGAD'>NAGAD</option>
                          <option value='BKASH'>BKASH</option>
                          <option value='ROCKET'>ROCKET</option>
                        </select>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group*/}
                      <div className='fv-row mb-5' id='withdrawalStatus'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Withdrawal Status
                        </label>
                        <select
                          {...formik.getFieldProps('withdrawalStatus')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='SUCCESS'>SUCCESS</option>
                          <option value='FAILED'>FAILED</option>
                          <option value='REJECTED'>REJECTED</option>
                        </select>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      <div className='fv-row mb-5' id='merchant'>
                        <label className='form-label fw-bolder text-dark fs-6'>Merchant</label>
                        <Select
                          ref={selectedMerchantCd}
                          className='basic-single'
                          classNamePrefix='select'
                          options={merchantCdList}
                          styles={customStyles}
                          isClearable={true}
                          name='merchantCd'
                          value={
                            formik.values.merchant
                              ? {value: formik.values.merchant, label: formik.values.merchant}
                              : null
                          }
                          onChange={(e: any) =>
                            e !== null
                              ? formik.setFieldValue('merchant', e.value)
                              : formik.setFieldValue('merchant', '')
                          }
                        />
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  {/* begin::Form group DateTime From */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>From</label>
                    <input
                      placeholder='Enter your start time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeFrom')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                        },
                        {
                          'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                        }
                      )}
                    />
                    {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeFrom}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group DateTime To */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>To</label>
                    <input
                      placeholder='Enter your end time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeTo')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                        },
                        {
                          'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                        }
                      )}
                    />
                    {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeTo}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group Match Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Patch Type</label>
                    <Select
                      ref={patchTypeSelectBtn}
                      isMulti
                      styles={customStyles}
                      name='patchType'
                      options={patchTypeList}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      value={
                        filterOptions.patchType
                          ? filterOptions.patchType
                              .split(',')
                              .map((item: any) => ({value: item, label: item}))
                          : ''
                      }
                      isClearable={true}
                      onChange={handlePatchType}
                    />
                  </div>{' '}
                  {/* end::Form group */}
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={() => {
                        patchTypeSelectBtn.current.setValue('')
                        // resetFilterOptions()
                        formik.resetForm({
                          values: {
                            search: '',
                            merchant: '',
                            walletCd: '',
                            withdrawalStatus: '',
                            dateTimeFrom: getCurrentDateInput(),
                            dateTimeTo: getCurrentDateInput(),
                            patchType: '',
                          },
                        })
                      }}
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>{' '}
    </div>
  )
}
