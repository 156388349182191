import styled from 'styled-components'

//Table
export const TableTemplateCSS = styled.div`
  padding: 0;
  .table {
    thead {
      background-color: var(--kt-card-bg);
      position: sticky;
      top: 0;
      z-index: 2;
      border-bottom: 1px solid rgba(182, 173, 178, 0.2);
    }
    th {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(182, 173, 178, 0.2);
      border-right: 1px solid rgba(182, 173, 178, 0.2);
      vertical-align: middle;
      position: relative;

      :last-child {
        border-right: 0;
        background-color: transparent;
        text-align: center;
      }
    }
    tbody {
      td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid rgba(182, 173, 178, 0.2);
        border-right: 1px solid rgba(182, 173, 178, 0.2);
        vertical-align: middle;

        :last-child {
          border-right: 0;
          text-align: center;
        }
      }
    }
  }
`
