import {create} from 'zustand'

export const useCustPendingDepStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    search: '',
    range: 'ALL',
    timezone: 'GMT+6',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
