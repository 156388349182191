import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'
import Select from 'react-select'
import {customStyles} from '../../../components/CustomStyles'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {useAuth} from '../../../auth'
import FormatNumberTo2Decimals from '../../../components/FormatNumberTo2Decimals'
import {sortByName} from '../../../components/SortByName'

const CreditReload_Create: React.FC = () => {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const navigate = useNavigate()
  const {currentUser, logout} = useAuth()
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const agentWalletRef = useRef<any>()
  const [loading, setLoading] = useState(false)
  const [agentWalletSelect, setAgentWalletSelect] = useState([])

  useEffect(() => {
    axios
      .post(
        `${SISPAY_API}/wallet/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result.wallet
          let uniqueWallets: any = []
          let seenValues = new Set()

          for (let i = 0; i < result.length; i++) {
            if (result[i].isAgentWallet === true && result[i].status !== 'DEACTIVE') {
              const value = result[i].username
              if (!seenValues.has(value)) {
                seenValues.add(value)
                uniqueWallets.push({
                  value,
                  label: value,
                })
              }
            }
          }

          setAgentWalletSelect(sortByName(uniqueWallets, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }, [])

  const initialValues = {
    username: '',
    amount: '',
    reqType: '',
    session: session,
  }

  const creditReloadCreateSchema = Yup.object().shape({
    username: Yup.string().required('Agent Wallet is required.'),
    reqType: Yup.string().required('Request Type is required.'),
    amount: Yup.number()
      .required('Amount is required.')
      .test('is-decimal', 'Amount must have 2 decimal places.', (value) => {
        if (value !== undefined) {
          // Modify the validation to check if the value has up to 2 decimal places
          return /^\d+(\.\d{0,2})?$/.test(value.toString())
        }
        return true // If value is undefined, no validation error
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: creditReloadCreateSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        axios
          .post(
            `${SISPAY_API}/agent/creditreload/create`,
            {
              request: {
                username: values.username,
                amount: values.amount,
                reqType: values.reqType,
                session: session,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            if (response.data.code == '200') {
              setLoading(false)
              navigate(-1)
              alert(response.data.message)
            } else {
              alert(response.data.message)
            }
          })
          .catch(function (error) {})
      } catch (error) {}
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Create Credit Reload
        </span>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          <div className='row'>
            <div className='col'>
              {/* begin::Form group Agent */}
              <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>Agent Wallet</label>
                <Select
                  ref={agentWalletRef}
                  className='basic-single'
                  classNamePrefix='select'
                  options={agentWalletSelect}
                  styles={customStyles}
                  isClearable={true}
                  name='username'
                  onBlur={() => {
                    formik.setFieldTouched('username')
                  }}
                  onChange={(e: any) =>
                    e !== null
                      ? formik.setFieldValue('username', e.value)
                      : formik.setFieldValue('username', '')
                  }
                />
                {formik.touched.username && formik.errors.username && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.username}</span>
                    </div>
                  </div>
                )}
              </div>{' '}
              {/* end::Form group */}
              {/* begin::Form group Status */}
              <div className='fv-row mb-8'>
                <label className='form-label required fw-bolder text-dark fs-6'>Request Type</label>
                <select
                  {...formik.getFieldProps('reqType')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.reqType && formik.errors.reqType,
                    },
                    {
                      'is-valid': formik.touched.reqType && !formik.errors.reqType,
                    }
                  )}
                  data-placeholder=''
                >
                  <option value=''>Please Select</option>
                  <option value='CREDIT-RELOAD'>CREDIT-RELOAD</option>
                  <option value='CREDIT-ADJUSTMENT'>CREDIT-ADJUSTMENT</option>
                </select>
                {formik.touched.reqType && formik.errors.reqType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.reqType}</span>
                    </div>
                  </div>
                )}
              </div>{' '}
              {/* end::Form group */}
              {/* begin::Form group Amount */}
              <div className='fv-row mb-8'>
                <label className='form-label required fw-bolder text-dark fs-6'>Amount</label>
                <input
                  placeholder='Enter Amount'
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('amount')}
                  onBlur={(e) => {
                    // Get the formatted value and set it back into the form field
                    const formattedValue = FormatNumberTo2Decimals(e.target.value)
                    formik.setFieldValue('amount', formattedValue)
                    formik.setFieldTouched('amount', true)
                  }}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.amount && formik.errors.amount,
                    },
                    {
                      'is-valid': formik.touched.amount && !formik.errors.amount,
                    }
                  )}
                />
                {formik.touched.amount && formik.errors.amount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.amount}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}
              {/* begin::Form group */}
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                >
                  {!loading && <span className='indicator-label'>Create</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Form group */}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreditReload_Create
