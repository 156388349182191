import axios from 'axios'
import {stringify} from 'querystring'
import {useEffect, useState} from 'react'
import {sortByName} from '../../../components/SortByName'
import {useWalletListStore} from './WalletList_Store'

export default function BulkDeactive(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  let selectedArray: any = props.selectedArray
  let setSelectedArray: any = props.setSelectedArray
  let setFormData = props.setFormData
  let setRowSelection = props.setRowSelection

  const [wallets, setWallets] = useState([])

  //Zustand Store
  const filterOptions = useWalletListStore((state: any) => state.filterOptions)
  const getData = useWalletListStore((state: any) => state.getData)
  const setFilterOptions = useWalletListStore((state: any) => state.setFilterOptions)
  const setGetData = useWalletListStore((state: any) => state.setGetData)

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveData(SISPAY_API: string, url: string, location: string) {
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        setWallets(response.data.result[`${location}`])
        //setFormData(sortByName(response.data.result[`${location}`], 'walletAccName'))
      })
      .catch(function (error) {})
  }

  //GET USER LIST
  useEffect(() => {
    retrieveData(SISPAY_API, '/wallet/list', 'wallet')
  }, [])

  function bulkDeactivate(selectedArray: any) {
    if (selectedArray.length !== 0) {
      axios
        .post(
          `${SISPAY_API}/wallet/status/bulkupdate`,
          {
            request: {
              session: session,
              status: 'INACTIVE',
              wallets: selectedArray,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == '200') {
            retrieveData(SISPAY_API, '/wallet/list', 'wallet')
            setRowSelection({})
            setGetData(!getData)
            alert(response.data.message)
          }
        })
        .catch(function (error) {
          alert(error)
        })
    }
  }

  return (
    <div
      className='modal fade'
      id='kt_modal_bulk_deactive'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Bulk In-Active Accounts
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body d-flex flex-wrap'>
            {selectedArray.length > 0
              ? selectedArray
                  .reduce((acc: any[], obj1: {id: any}) => {
                    const matchObj = wallets.find((obj2: any) => obj1.id === obj2.id)
                    if (matchObj) {
                      acc.push(matchObj)
                    }
                    return acc
                  }, [])
                  .map((data: any, index: number) => {
                    return (
                      <div
                        className='d-flex d-flex-column me-5 mb-5'
                        style={{
                          width: 'auto',
                        }}
                      >
                        <div
                          style={{
                            width: 'auto',
                            backgroundColor: 'grey',
                            padding: '0 10px 0 10px',
                            color: 'white',
                            textAlign: 'center',
                            borderRadius: '5px 0 0 5px',
                          }}
                        >
                          {' '}
                          {data.walletAccName}
                        </div>
                        <div
                          style={{
                            width: 'auto',
                            padding: '0 10px 0 10px',
                            backgroundColor: 'white',
                            color: 'black',
                            textAlign: 'center',
                            borderRadius: '0 5px 5px 0',
                          }}
                        >
                          {data.walletAccNo}
                        </div>
                      </div>
                    )
                  })
              : 'No Wallet Selected'}
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
              Close
            </button>
            {selectedArray.length > 0 && (
              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  bulkDeactivate(selectedArray)
                  setSelectedArray([])
                }}
              >
                In-Active
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
