import {stat} from 'fs'
import {create} from 'zustand'

export const useCustCodeBlackListStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    search: '',
    status: 'ACTIVE',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
