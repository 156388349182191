import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {Filter} from './components/filter'
import BulkActive from './components/bulkActive'
import BulkDeactive from './components/bulkDeactive'
import ActivateAll from './components/activateAll'
import DeactivateAll from './components/deactivateAll'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../../components/Popover'
import {Export_CSV} from '../../components/Export_CSV'
import {Export_Excel} from '../../components/Export_Excel'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'
import {WalletList_ExportPDF} from './components/WalletList_ExportPDF'
import FormatNumber from '../../components/FormatNumber'
import {useWalletListStore} from './components/WalletList_Store'
import TableTemplate from '../../components/Table/TableTemplate'
import {TableTemplateCSS} from '../../components/Table/TableCSS'
import IndeterminateCheckbox from '../../components/Table/IndeterminateCheckbox'
import {ColumnDef, ColumnPinningState} from '@tanstack/react-table'
import FormatDate from '../../components/FormatDate'

const WalletListPage: React.FC = () => {
  const userType: string = localStorage.getItem('userType') || ''
  const userRole: string = localStorage.getItem('userRole') || ''
  const username: string = localStorage.getItem('username') || ''

  //Table Related
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['select'],
    right: ['action'],
  })
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 200, //default page size
  })
  const [totalCount, setTotalCount] = useState(0)
  useEffect(() => {
    const selectedIndices = Object.keys(rowSelection).map((key) => parseInt(key, 10))
    const filteredSelectedRowData: any = formData
      .filter((_: any, index: number) => selectedIndices.includes(index))
      .map((row: any) => row)

    setSelectedArray(filteredSelectedRowData)
  }, [rowSelection])
  //Table Related

  //States
  const [formData, setFormData] = useState([])
  const [selectedArray, setSelectedArray] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [isAction, setIsAction] = useState(false)
  const [downloadAction, setDownloadAction] = useState(false)
  const [walletMetrics, setWalletMetrics] = useState<any>(undefined)

  //Zustand Store
  const setGetData = useWalletListStore((state: any) => state.setGetData)

  // useEffect(() => {
  //   let walletMetrics = localStorage.getItem('walletMetrics')
  //   if (walletMetrics) {
  //     setWalletMetrics(JSON.parse(walletMetrics))
  //   } else {
  //     setWalletMetrics([])
  //   }
  // }, [formData, showMetrics])

  useEffect(() => {
    const selectedIndices = Object.keys(rowSelection).map((key) => parseInt(key, 10))
    const filteredSelectedRowData: any = formData
      .filter((_: any, index: number) => selectedIndices.includes(index))
      .map((row: any) => ({id: row.id}))

    setSelectedArray(filteredSelectedRowData)
  }, [rowSelection])

  const keyMap = {
    id: 'ID',
    walletAccName: 'Wallet Account Name',
    walletAccNo: 'Wallet Account No',
    walletCd: 'Wallet Code',
    walletType: 'Wallet Type',
    status: 'Status',
    merchantName: 'Merchant Name',
    startBal: 'Starting Balance',
    balDiff: 'Balance Different',
    agentName: 'Agent Name',
    createdDate: 'Created Date',
    deactivatedDT: 'Deactivated Date',
    walletBal: 'Wallet Balance',
    isAgentWallet: 'Is Agent Wallet',
    agentCd: 'Agent Code',
    prefAcc: 'Preferred Account',
    weightage: 'Weightage',
    maxDeposit: 'Max Deposit',
    maxWithdrawal: 'Max Withdrawal',
    minDeposit: 'Min Deposit',
    minWithdrawal: 'Min Withdrawal',
    dailyDepositLmt: 'Daily Deposit Limit',
    dailyWithdrawalLmt: 'Daily Withdrawal Limit',
  }

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        enableColumnFilter: false,
        size: 50,
        header: ({table}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        ),
        cell: ({row}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: 'walletAccName',
        header: 'Wallet Account Name',
        accessorKey: 'walletAccName',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Account No',
        accessorKey: 'walletAccNo',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Code',
        accessorKey: 'walletCd',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Type',
        accessorKey: 'walletType',
        cell: (info: any) => {
          if (info.getValue() == 'DEPOSIT') {
            return 'DEPOSIT'
          } else if (info.getValue() == 'WITHDRAWAL') {
            return 'WITHDRAWAL'
          } else {
            return 'DEPOSIT & WITHDRAWAL'
          }
        },
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Merchant Name',
        accessorKey: 'merchantName',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Starting Balance',
        accessorKey: 'startBal',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Balance Different',
        accessorKey: 'balDiff',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Agent Name',
        accessorKey: 'agentName',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Update By',
        accessorKey: 'updateBy',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Created Date',
        accessorKey: 'createdDate',
        cell: (info: any) => FormatDate(info.getValue()),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Deactivated Date',
        accessorKey: 'deactivatedDT',
        cell: (info: any) => FormatDate(info.getValue()),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Action',
        accessorKey: 'action',
        size: 100,
        cell: (info: any) => {
          return (
            <Link
              to={`/wallet/list/edit/${info.row.original.walletAccNo}`}
              state={info.row.original}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setGetData(true)}
            >
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement='top'
                overlay={popover('Edit')}
              >
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                </div>
              </OverlayTrigger>{' '}
            </Link>
          )
        },
        enableColumnFilter: false,
      },
    ],
    []
  )

  const columns_itadmin = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        enableColumnFilter: false,
        size: 50,
        header: ({table}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        ),
        cell: ({row}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      // {
      //   header: 'ID',
      //   accessorKey: 'id',
      //   cell: (info) => info.getValue(),
      //   enableColumnFilter: false,
      // },
      {
        id: 'walletAccName',
        header: 'Wallet Account Name',
        accessorKey: 'walletAccName',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Account No',
        accessorKey: 'walletAccNo',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Code',
        accessorKey: 'walletCd',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Telco Name',
        accessorKey: 'telcoName',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Pin Number',
        accessorKey: 'pinNumber',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Type',
        accessorKey: 'walletType',
        cell: (info: any) => {
          if (info.getValue() == 'DEPOSIT') {
            return 'DEPOSIT'
          } else if (info.getValue() == 'WITHDRAWAL') {
            return 'WITHDRAWAL'
          } else {
            return 'DEPOSIT & WITHDRAWAL'
          }
        },
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Merchant Name',
        accessorKey: 'merchantName',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Starting Balance',
        accessorKey: 'startBal',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Balance Different',
        accessorKey: 'balDiff',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Agent Name',
        accessorKey: 'agentName',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Update By',
        accessorKey: 'updateBy',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Created Date',
        accessorKey: 'createdDate',
        cell: (info: any) => FormatDate(info.getValue()),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Deactivated Date',
        accessorKey: 'deactivatedDT',
        cell: (info: any) => FormatDate(info.getValue()),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Action',
        accessorKey: 'action',
        size: 100,
        cell: (info: any) => {
          return (
            <Link
              to={`/wallet/list/edit/${info.row.original.walletAccNo}`}
              state={info.row.original}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setGetData(true)}
            >
              <OverlayTrigger trigger='hover' placement='top' overlay={popover('Edit')}>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                </div>
              </OverlayTrigger>{' '}
            </Link>
          )
        },
        enableColumnFilter: false,
      },
    ],
    []
  )

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0 d-flex'>
          <div
            className='position-relative me-5'
            style={{
              width: '180px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-info w-100'
              onClick={() => {
                setDownloadAction(!downloadAction)
              }}
            >
              Download Record As:
            </div>{' '}
            {downloadAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_CSV(
                      formData,
                      keyMap,
                      getCurrentDateInput(),
                      getCurrentDateInput(),
                      'Wallet_List'
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  CSV
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_Excel(
                      formData,
                      keyMap,
                      getCurrentDateInput(),
                      getCurrentDateInput(),
                      'Wallet_List'
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  Excel WorkBook
                </button>{' '}
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    WalletList_ExportPDF(formData, getCurrentDateInput(), getCurrentDateInput())
                    setDownloadAction(!downloadAction)
                  }}
                >
                  PDF
                </button>{' '}
              </div>
            )}
          </div>
          {userType === 'Admin' && userRole === 'operation' ? (
            ''
          ) : (
            <Link
              to='/wallet/list/create'
              className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-primary me-5'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-plus me-1'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add Wallet
            </Link>
          )}{' '}
          <div
            className='position-relative me-5'
            style={{
              width: '150px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-warning w-100'
              onClick={() => {
                setIsAction(!isAction)
              }}
            >
              Action
            </div>{' '}
            {isAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_bulk_active'
                  disabled={Object.keys(rowSelection).length > 0 ? false : true}
                  onClick={() => {
                    setIsAction(!isAction)
                  }}
                >
                  BULK ACTIVE
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_bulk_deactive'
                  disabled={Object.keys(rowSelection).length > 0 ? false : true}
                  onClick={() => {
                    setIsAction(!isAction)
                  }}
                >
                  BULK INACTIVE
                </button>{' '}
              </div>
            )}
          </div>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_active_all'
          >
            ACTIVE ALL
          </button>{' '}
          <div
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_deactive_all'
          >
            IN-ACTIVE ALL
          </div>{' '}
        </div>

        <div className='m-0 d-flex '>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter
        setFormData={setFormData}
        setSelectedArray={setSelectedArray}
        setRowSelection={setRowSelection}
      />
      <TableTemplateCSS>
        <TableTemplate
          data={formData}
          columns={username.includes('itadmin') ? columns_itadmin : columns}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnPinning={columnPinning}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          showMetrics={showMetrics}
          manualPagination={false}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={totalCount}
        />
      </TableTemplateCSS>
      <BulkActive
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        setFormData={setFormData}
        setRowSelection={setRowSelection}
      />
      <BulkDeactive
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        setFormData={setFormData}
        setRowSelection={setRowSelection}
      />
      <ActivateAll setFormData={setFormData} />
      <DeactivateAll setFormData={setFormData} />
      {/* Modal */}
    </div>
  )
}

export default WalletListPage
