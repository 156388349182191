import React from 'react'
import ManualDepositCreate_Merchant from './Deposit-Manual-Create-Merchant'
import DepositManagementPage_BO from './Deposit-Manual-Create-BO'
import {useNavigate} from 'react-router-dom'

const DepositManagementPage_ManualDeposit: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div>
      <a
        onClick={() => navigate(-1)}
        className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3 mb-5'
      >
        Back
      </a>
      <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
        <li className='nav-item'>
          <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
            Mock Merchant Submission
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
            BO Creation
          </a>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
          <ManualDepositCreate_Merchant />
        </div>
        <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
          <DepositManagementPage_BO />
        </div>
      </div>
    </div>
  )
}

export default DepositManagementPage_ManualDeposit
