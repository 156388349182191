import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'
import {ManualMatching} from '../components/Deposit-Manual-Matching-Modal'
import {ErrorHandling} from '../../components/ErrorHandling'
import {useAuth} from '../../auth/core/Auth'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'
import {sortByName} from '../../components/SortByName'

const DepositManagementPage_Edit: React.FC = () => {
  const {logout} = useAuth()
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const navigate = useNavigate()
  const location: any = useLocation()
  const [loading, setLoading] = useState(false)
  const [editDisabled, setEditDisabled] = useState(true)
  const [walletAccNoList, setWalletAccNoList] = useState([])
  const [initialWalletCd, setInitialWalletCd] = useState(location.state.walletCd)
  const [initialAmount, setInitialAmount] = useState(location.state.amount)
  const [initialSubmittedTxnId, setInitialSubmittedTxnId] = useState(location.state.submittedTxnId)
  const [initialWalletAccNo, setInitialWalletAccNo] = useState(location.state.walletAccNo)
  const [initialMerchantName, setInitialMerchantName] = useState(location.state.merchantName)

  function retrieveWalletAccNo(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          const uniqueSet = new Set()
          const walletAccNoList = result
            .filter((item: any) => item.walletAccNo !== '')
            .filter((item: any) => item.walletCd == initialWalletCd)
            .filter((item: any) => item.merchantName.includes(initialMerchantName))
            .filter((item: any) => item.status == 'ACTIVE' || item.status == 'INACTIVE')
            .reduce((result: any, item: any) => {
              if (!uniqueSet.has(item.walletAccNo)) {
                uniqueSet.add(item.walletAccNo)
                result.push({
                  value: item.walletAccNo,
                  label: `${item.walletAccName} - ${item.walletAccNo}`,
                  walletCd: item.walletCd,
                  merchantName: item.merchantName,
                })
              }
              return result
            }, [])

          setWalletAccNoList(sortByName(walletAccNoList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    retrieveWalletAccNo(SISPAY_API, '/wallet/list', 'wallet')
  }, [])

  const initialValues = {
    id: String(location.state.id),
    depositDT: String(location.state.depositDT).replace('T', ' ').replace(/\.\d+/, ''),
    merchant: String(location.state.merchantName) + ' - ' + String(location.state.merchantCd),
    merchantName: String(location.state.merchantName),
    merchantCd: String(location.state.merchantCd),
    customerCd: String(location.state.customerCd),
    walletAccName: String(location.state.walletAccName),
    walletCd: String(location.state.walletCd),
    amount: String(location.state.amount),
    status: String(location.state.depositStatus),
    callbackStatus: String(location.state.callbackStatus),
    depositFee: String(location.state.depositFee),
    adjustmentRemark: String(location.state.adjustmentRemark),
    approvalRejectionRemark: String(location.state.approvalRejectionRemark),
    submittedTxnId: String(location.state.submittedTxnId),
    walletAccNo: String(location.state.walletAccNo),
    agentName: String(location.state.agentName),
    merchantTxnId: String(location.state.merchantTxnId),
    srcWalletAcc: String(location.state.srcWalletAcc),
    reference: String(location.state.reference),
    matchStatus: String(location.state.matchStatus),
    adjRemark: String(location.state.adjustmentRemark),
  }

  const editDepositSchema = Yup.object().shape({
    walletAccNo: Yup.string(),
    amount: Yup.string(),
    adjustmentRemark: Yup.string(),
    submittedTxnId: Yup.string().required('Submitted Transaction ID is required'),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: editDepositSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      let amountResult = true
      let submittedTxnIdResult = true
      let walletAccNoResult = true

      const amountPromise: any =
        values.amount !== initialAmount
          ? axios.post(
              `${SISPAY_API}/cash/depositrequest/update`,
              {
                request: {
                  session: session,
                  id: Number(values.id),
                  adjustmentRemark: values.adjustmentRemark,
                  amount: Number(values.amount),
                },
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
          : Promise.resolve()

      const walletAccNoPromise: any =
        values.walletAccNo !== initialWalletAccNo
          ? axios.post(
              `${SISPAY_API}/cash/depositrequest/admin/updatewalletaccno`,
              {
                request: {
                  session: session,
                  id: Number(values.id),
                  walletAccNo: values.walletAccNo,
                },
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
          : Promise.resolve()

      const submittedTxnIdPromise: any =
        values.submittedTxnId !== initialSubmittedTxnId
          ? axios.post(
              `${SISPAY_API}/cash/depositrequest/admin/updatetxnid`,
              {
                request: {
                  session: session,
                  id: Number(values.id),
                  txnId: values.submittedTxnId,
                },
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
          : Promise.resolve()

      try {
        // Wait for all promises to settle
        await Promise.all([amountPromise, walletAccNoPromise, submittedTxnIdPromise])

        // Wait for all promises to settle
        const [amountResponse, walletAccNoResponse, submittedTxnIdResponse] = await Promise.all([
          amountPromise,
          walletAccNoPromise,
          submittedTxnIdPromise,
        ])

        // Handle results
        if (amountResponse && amountResponse.data && amountResponse.data.code == 400) {
          amountResult = false
          setLoading(false)
          alert(amountResponse.data.message)
        }

        if (
          walletAccNoResponse &&
          walletAccNoResponse.data &&
          walletAccNoResponse.data.code == 400
        ) {
          walletAccNoResult = false
          setLoading(false)
          formik.errors.walletAccNo = walletAccNoResponse.data.message
          alert(walletAccNoResponse.data.message)
        }

        if (
          submittedTxnIdResponse &&
          submittedTxnIdResponse.data &&
          submittedTxnIdResponse.data.code == 400
        ) {
          submittedTxnIdResult = false
          setLoading(false)
          formik.errors.submittedTxnId = submittedTxnIdResponse.data.message
          alert(submittedTxnIdResponse.data.message)
        }

        if (amountResult && submittedTxnIdResult && walletAccNoResult) {
          setLoading(false)
          navigate(-1)
          alert('Deposit Request Updated!')
        }
      } catch (error) {
        alert(error)
      }
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <a
          onClick={() => {
            setEditDisabled(!editDisabled)
          }}
          className='btn btn-warning ml-auto'
          style={{
            display:
              formik.values.status === 'INCOMPLETE' ||
              formik.values.status === 'PENDING' ||
              formik.values.status == 'PENDING-RECHECK' ||
              formik.values.status == 'FAILED'
                ? 'block'
                : 'none',
          }}
        >
          Edit
        </a>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group Request ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Request ID</label>
            <input
              placeholder='Enter your request ID'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('id')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.id && formik.errors.id,
                },
                {
                  'is-valid': formik.touched.id && !formik.errors.id,
                }
              )}
            />
            {formik.touched.id && formik.errors.id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.id}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Deposit DateTime */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Deposit DateTime</label>
            <input
              placeholder='Enter Deposit DateTime'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('depositDT')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.depositDT && formik.errors.depositDT,
                },
                {
                  'is-valid': formik.touched.depositDT && !formik.errors.depositDT,
                }
              )}
            />
            {formik.touched.depositDT && formik.errors.depositDT && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.depositDT}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Merchant */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Merchant</label>
            <input
              placeholder='Enter Merchant'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('merchant')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.merchant && formik.errors.merchant,
                },
                {
                  'is-valid': formik.touched.merchant && !formik.errors.merchant,
                }
              )}
            />
            {formik.touched.merchant && formik.errors.merchant && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.merchant}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Customer Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Customer Code</label>
            <input
              placeholder='Enter Customer Code'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('customerCd')}
              className={clsx(
                'form-control bg-grey',
                {'is-invalid': formik.touched.customerCd && formik.errors.customerCd},
                {
                  'is-valid': formik.touched.customerCd && !formik.errors.customerCd,
                }
              )}
            />
            {formik.touched.customerCd && formik.errors.customerCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customerCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account Name */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Account Name</label>
            <input
              placeholder='Enter Wallet Account Name'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('walletAccName')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccName && formik.errors.walletAccName,
                },
                {
                  'is-valid': formik.touched.walletAccName && !formik.errors.walletAccName,
                }
              )}
            />
            {formik.touched.walletAccName && formik.errors.walletAccName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
            <input
              placeholder='Enter Wallet Code'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('walletCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                },
                {
                  'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                }
              )}
            />
            {formik.touched.walletCd && formik.errors.walletCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Amount */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Amount</label>
            <input
              placeholder='Enter Amount'
              type='text'
              autoComplete='off'
              disabled={editDisabled ? true : false}
              {...formik.getFieldProps('amount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.amount && formik.errors.amount,
                },
                {
                  'is-valid': formik.touched.amount && !formik.errors.amount,
                }
              )}
            />
            {formik.touched.amount && formik.errors.amount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.amount}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Status */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Status</label>
            <input
              placeholder='Enter Status'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('status')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.status && formik.errors.status,
                },
                {
                  'is-valid': formik.touched.status && !formik.errors.status,
                }
              )}
            />
            {formik.touched.status && formik.errors.status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.status}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Callback Status */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Callback Status</label>
            <input
              placeholder={formik.values.callbackStatus == '' ? 'EMPTY' : 'Enter Callback Status'}
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('callbackStatus')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.callbackStatus && formik.errors.callbackStatus,
                },
                {
                  'is-valid': formik.touched.callbackStatus && !formik.errors.callbackStatus,
                }
              )}
            />
            {formik.touched.callbackStatus && formik.errors.callbackStatus && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.callbackStatus}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Deposit Fee */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Deposit Fee</label>
            <input
              placeholder='Enter Deposit Fee'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('depositFee')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.depositFee && formik.errors.depositFee,
                },
                {
                  'is-valid': formik.touched.depositFee && !formik.errors.depositFee,
                }
              )}
            />
            {formik.touched.depositFee && formik.errors.depositFee && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.depositFee}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Adjustment Remark */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Adjustment Remark</label>
            <input
              placeholder='Enter Adjustment Remark'
              type='text'
              autoComplete='off'
              disabled={editDisabled ? true : false}
              {...formik.getFieldProps('adjustmentRemark')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.adjustmentRemark && formik.errors.adjustmentRemark,
                },
                {
                  'is-valid': formik.touched.adjustmentRemark && !formik.errors.adjustmentRemark,
                }
              )}
            />
            {formik.touched.adjustmentRemark && formik.errors.adjustmentRemark && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.adjustmentRemark}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Approval/Rejection Remark */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Approval/Rejection Remark</label>
            <input
              placeholder={
                formik.values.approvalRejectionRemark == ''
                  ? 'EMPTY'
                  : 'Enter Approval/Rejection Remark'
              }
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('approvalRejectionRemark')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid':
                    formik.touched.approvalRejectionRemark && formik.errors.approvalRejectionRemark,
                },
                {
                  'is-valid':
                    formik.touched.approvalRejectionRemark &&
                    !formik.errors.approvalRejectionRemark,
                }
              )}
            />
            {formik.touched.approvalRejectionRemark && formik.errors.approvalRejectionRemark && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.approvalRejectionRemark}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Submitted Transaction ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Submitted Transaction ID</label>
            <input
              placeholder='Enter Submitted Transaction ID'
              type='text'
              autoComplete='off'
              disabled={editDisabled}
              {...formik.getFieldProps('submittedTxnId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.submittedTxnId && formik.errors.submittedTxnId,
                },
                {
                  'is-valid': formik.touched.submittedTxnId && !formik.errors.submittedTxnId,
                }
              )}
            />
            {formik.touched.submittedTxnId && formik.errors.submittedTxnId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.submittedTxnId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account No */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Account No</label>
            <Select
              //ref={selectedWalletAccNoRef}
              styles={customStyles}
              isDisabled={editDisabled}
              className='basic-single'
              classNamePrefix='select'
              name='walletAccNo'
              options={walletAccNoList}
              isClearable={true}
              value={{
                label:
                  formik.getFieldProps('walletAccName').value +
                  ' - ' +
                  formik.getFieldProps('walletAccNo').value,
                value: formik.getFieldProps('walletAccNo').value,
              }}
              onChange={(e: any) => {
                if (e !== null) {
                  formik.setFieldValue('walletAccNo', e.value)
                  formik.setFieldValue('walletAccName', e.label.split(' - ')[0])
                } else {
                  formik.setFieldValue('walletAccNo', '')
                  formik.setFieldValue('walletAccName', '')
                }
              }}
            />
            {formik.touched.walletAccNo && formik.errors.walletAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Agent */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Agent</label>
            <input
              placeholder='Enter Agent'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('agentName')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.agentName && formik.errors.agentName,
                },
                {
                  'is-valid': formik.touched.agentName && !formik.errors.agentName,
                }
              )}
            />
            {formik.touched.agentName && formik.errors.agentName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.agentName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Merchant Transaction ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Merchant Transaction ID</label>
            <input
              placeholder='Enter Merchant Transaction ID'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('merchantTxnId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.merchantTxnId && formik.errors.merchantTxnId,
                },
                {
                  'is-valid': formik.touched.merchantTxnId && !formik.errors.merchantTxnId,
                }
              )}
            />
            {formik.touched.merchantTxnId && formik.errors.merchantTxnId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.merchantTxnId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Source Wallet Account */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Source Wallet Account</label>
            <input
              placeholder='Enter Source Wallet Account'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('srcWalletAcc')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.srcWalletAcc && formik.errors.srcWalletAcc,
                },
                {
                  'is-valid': formik.touched.srcWalletAcc && !formik.errors.srcWalletAcc,
                }
              )}
            />
            {formik.touched.srcWalletAcc && formik.errors.srcWalletAcc && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.srcWalletAcc}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Reference */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Reference</label>
            <input
              placeholder={formik.values.reference == '' ? 'EMPTY' : 'Enter Reference'}
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('reference')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.reference && formik.errors.reference,
                },
                {
                  'is-valid': formik.touched.reference && !formik.errors.reference,
                }
              )}
            />
            {formik.touched.reference && formik.errors.reference && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.reference}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Match Status */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Match Status</label>
            <input
              placeholder='Enter Match Status'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('matchStatus')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.matchStatus && formik.errors.matchStatus,
                },
                {
                  'is-valid': formik.touched.matchStatus && !formik.errors.matchStatus,
                }
              )}
            />
            {formik.touched.matchStatus && formik.errors.matchStatus && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.matchStatus}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || editDisabled}
              style={{
                display:
                  formik.values.status === 'INCOMPLETE' ||
                  formik.values.status === 'PENDING' ||
                  formik.values.status == 'PENDING-RECHECK' ||
                  formik.values.status == 'FAILED'
                    ? 'block'
                    : 'none',
              }}
            >
              {!loading && <span className='indicator-label'>Update</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
        <div
          className='mx-10 mt-0 mb-10'
          style={{
            display:
              formik.values.status === 'INCOMPLETE' ||
              formik.values.status === 'PENDING' ||
              formik.values.status == 'PENDING-RECHECK' ||
              formik.values.status == 'FAILED'
                ? 'block'
                : 'none',
          }}
        >
          {/* <a
            className='btn btn-danger w-100'
            data-bs-toggle='modal'
            href='#exampleModalToggle'
            role='button'
          >
            MANUAL MATCHING
          </a> */}
        </div>
        {/* {formik.values.status === 'INCOMPLETE' ||
        formik.values.status == 'PENDING' ||
        formik.values.status == 'PENDING-RECHECK' ||
        formik.values.status == 'FAILED' ? (
          <ManualMatching depositData={depositData} />
        ) : (
          ''
        )} */}
      </div>
    </div>
  )
}

export default DepositManagementPage_Edit
