import {saveAs} from 'file-saver'
import {getCurrentDateTime} from '../../../components/GetCurrentDatetime'
import {modifyKeys} from '../../../components/ModifyKeys'

export function CustPendingDepExportExcel(data: any, customerCd: any) {
  const keyMap = {
    id: 'ID',
    depositDT: 'Deposit DateTime',
    merchantName: 'Merchant',
    merchantCd: 'Merchant Code',
    customerCd: 'Customer Code',
    walletAccName: 'Wallet Account Name',
    walletCd: 'Wallet Account Code',
    amount: 'Amount',
    depositStatus: 'Deposit Status',
    callbackStatus: 'Callback Status',
    depositFee: 'Deposit Fee',
    adjustmentRemark: 'Adjustment Remark',
    approvalRejectionRemark: 'Approval/ Rejection Remark',
    submittedTxnId: 'Submitted Transaction ID',
    walletAccNo: 'Wallet Account No',
    merchantTxnId: 'Merchant Transaction ID',
    srcWalletAcc: 'Source Wallet Acc',
    reference: 'Reference',
    matchStatus: 'Match Status',
    completedDT: 'Completed DateTime',
    successBy: 'Success By',
  }

  const XLSX = require('xlsx')

  let dataset: any = []

  for (let i = 0; i < data.length; i++) {
    dataset.push({
      id: data[i].id,
      depositDT: data[i].depositDT,
      merchantName: data[i].merchantName,
      merchantCd: data[i].merchantCd,
      customerCd: data[i].customerCd,
      walletAccName: data[i].walletAccName,
      walletCd: data[i].walletCd,
      amount: data[i].amount,
      depositStatus: data[i].depositStatus,
      callbackStatus: data[i].callbackStatus,
      depositFee: data[i].depositFee,
      adjustmentRemark: data[i].adjustmentRemark,
      approvalRejectionRemark: data[i].approvalRejectionRemark,
      submittedTxnId: data[i].submittedTxnId,
      walletAccNo: data[i].walletAccNo,
      merchantTxnId: data[i].merchantTxnId,
      srcWalletAcc: data[i].srcWalletAcc,
      reference: data[i].reference,
      matchStatus: data[i].matchStatus,
      completedDT: data[i].completedDT,
      successBy: data[i].successBy,
    })
  }

  dataset = modifyKeys(dataset, keyMap)

  const worksheet = XLSX.utils.json_to_sheet(dataset)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
  const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'buffer'})
  const fileData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(fileData, `Customer Pending Deposit - ${customerCd} - ${getCurrentDateTime()}.xlsx`) // Requires the FileSaver library or a similar method
}
