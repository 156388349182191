import {saveAs} from 'file-saver'
import {getCurrentDateTime} from '../../components/GetCurrentDatetime'

export function BulkWithdrawalUpload_ExportExcel(response: any) {
  const XLSX = require('xlsx')

  let dataset: any = []

  for (let i = 0; i < response.result.details.length; i++) {
    dataset.push({
      Row: response.result.details[i].row,
      Status: response.result.details[i].status,
      Feedback: response.result.details[i].msg,
      MerchantCode: response.result.details[i].data.MerchantCode,
      CustomerCode: response.result.details[i].data.CustomerCode,
      Currency: response.result.details[i].data.Currency,
      BankCode: response.result.details[i].data.BankCode,
      Amount: response.result.details[i].data.Amount,
      MerchantTransID: response.result.details[i].data.MerchantTransID,
      Reference: response.result.details[i].data.Reference,
      TransactionTimeStamp: response.result.details[i].data.TransactionTimeStamp,
      ClientIP: response.result.details[i].data.ClientIP,
      ResultURL: response.result.details[i].data.ResultURL,
      ResponseURL: response.result.details[i].data.ResponseURL,
      DestinationAccountName: response.result.details[i].data.DestinationAccountName,
      DestinationAccountNo: response.result.details[i].data.DestinationAccountNo,
      WalletAccountNo: response.result.details[i].data.WalletAccountNo,
    })
  }

  const worksheet = XLSX.utils.json_to_sheet(dataset)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
  const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'buffer'})
  const fileData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(fileData, `Bulk Withdrawal Report - ${getCurrentDateTime()}.xlsx`) // Requires the FileSaver library or a similar method
}
