import {useRef} from 'react'
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {useDepositStore} from './Deposit-Store'
import {useAuth} from '../../../auth'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'
import {sortByDate} from '../../../components/SortByDate'
import {UniqueWalletAccNo} from '../../../components/UniqueWalletAccNo'
import {sortByName} from '../../../components/SortByName'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {customStyles} from '../../../components/CustomStyles'
import Modal from '../../../components/Modal'

export function Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData
  let depositFilterBtn = props.depositFilterBtn
  let currentPage = props.pagination.pageIndex
  let setCurrentPage = props.setCurrentPage
  let setTotalCount = props.setTotalCount
  let setFilterValues = props.setFilterValues
  let downloadLoading = props.downloadLoading
  let firstLoad = props.firstLoad
  let setFirstLoad = props.setFirstLoad

  console.log(currentPage)

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [merchantCdList, setMerchantCdList] = useState(Object)
  const [walletAccNoList, setWalletAccNoList] = useState(Object)

  //Refs
  const selectedMerchantCd = useRef<any>()
  const selectedMatchStatusRef = useRef<any>()
  const selectedWalletAccNoRef = useRef<any>()
  const selectedDepositStatusRef = useRef<any>()

  //Zustand Store
  const filterOptions = useDepositStore((state: any) => state.filterOptions)
  const getData = useDepositStore((state: any) => state.getData)
  const setFilterOptions = useDepositStore((state: any) => state.setFilterOptions)
  const setGetData = useDepositStore((state: any) => state.setGetData)

  const handleMatchStatusChange = (selected: any) => {
    if (selected !== '') {
      setFilterOptions({
        ...filterOptions,
        matchStatus: selected.map((option: any) => option.value).join('|'),
      })
    } else {
      setFilterOptions({...filterOptions, matchStatus: ''})
    }
  }

  const handleSelectedDepositStatusChange = (selected: any) => {
    if (selected !== '') {
      setFilterOptions({
        ...filterOptions,
        depositStatus: selected.map((option: any) => option.value).join('|'),
      })
    } else {
      setFilterOptions({...filterOptions, depositStatus: ''})
    }
  }

  const initialValues = {
    searchTransaction: filterOptions.searchTransaction
      ? String(filterOptions.searchTransaction)
      : '',
    searchCustomer: filterOptions.searchCustomer ? String(filterOptions.searchCustomer) : '',
    searchAmount: filterOptions.searchAmount ? Number(filterOptions.searchAmount) : '',
    merchantCd: filterOptions.merchantCd ? String(filterOptions.merchantCd) : '',
    walletAccNo: filterOptions.walletAccNo ? String(filterOptions.walletAccNo) : '',
    walletCd: filterOptions.walletCd ? String(filterOptions.walletCd) : '',
    matchStatus: filterOptions.matchStatus ? String(filterOptions.matchStatus) : '',
    callbackStatus: filterOptions.callbackStatus ? String(filterOptions.callbackStatus) : '',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
    depositStatus: filterOptions.depositStatus ? String(filterOptions.depositStatus) : '',
  }

  const filterSchema = Yup.object().shape({
    searchTransaction: Yup.string(),
    searchCustomer: Yup.string(),
    searchAmount: Yup.number(),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

      setLoading(true)
      setDisabled(true)

      let searchTransaction = values.searchTransaction.trim()
      let searchCustomer = values.searchCustomer.trim()
      let searchAmount = values.searchAmount
      let merchantCd = values.merchantCd.trim()
      let walletAccNo = values.walletAccNo.trim()
      let walletCd = values.walletCd.trim()
      let depositStatus = filterOptions.depositStatus
      let matchStatus = filterOptions.matchStatus
      let callbackStatus = values.callbackStatus.trim()
      let dateTimeFrom = values.dateTimeFrom.trim()
      let dateTimeTo = values.dateTimeTo.trim()

      // Add depositStatus to the values object
      values.depositStatus = depositStatus
      values.matchStatus = matchStatus

      setFilterValues(values)
      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/cash/depositrequest/list`,
          {
            request: {
              action: 'list',
              session: session,
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
              page: '1',
              //filter
              ...(depositStatus && {byDepoStatus: depositStatus}),
              ...(merchantCd && {byMerchantCd: merchantCd}),
              ...(walletAccNo && {byWalletAccNo: walletAccNo}),
              ...(walletCd && {byWalletCd: walletCd}),
              ...(matchStatus && {byMatchStatus: matchStatus}),
              ...(searchTransaction && {byTransaction: searchTransaction}),
              ...(searchCustomer && {byCustomer: searchCustomer}),
              ...(searchAmount && {byAmount: searchAmount}),
              ...(callbackStatus && {byCallbackStatus: callbackStatus}),
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let formData = response.data.result.depositRequestList

          setFormData(sortByDate(formData, 'depositDT'))
          setTotalCount(response.data.result.totalCount ? response.data.result.totalCount : 0)
          setFirstLoad(false)

          setDisabled(false)
          setLoading(false)

          if (currentPage !== 0) {
            setCurrentPage(1)
          }
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false && firstLoad == true) return

    if (getData == true) {
      setCurrentPage({
        pageIndex: 0,
        pageSize: 200,
      })
    }

    setDisabled(true)
    setLoading(true)

    let searchTransaction = formik.values.searchTransaction.trim()
    let searchCustomer = formik.values.searchCustomer.trim()
    let searchAmount = formik.values.searchAmount
    let merchantCd = formik.values.merchantCd.trim()
    let walletAccNo = formik.values.walletAccNo.trim()
    let walletCd = formik.values.walletCd.trim()
    let depositStatus = filterOptions.depositStatus
    let matchStatus = filterOptions.matchStatus
    let callbackStatus = formik.values.callbackStatus.trim()
    let dateTimeFrom = formik.values.dateTimeFrom.trim()
    let dateTimeTo = formik.values.dateTimeTo.trim()

    // Add depositStatus to the values object
    formik.values.depositStatus = depositStatus
    formik.values.matchStatus = matchStatus

    axios
      .post(
        `${SISPAY_API}/cash/depositrequest/list`,
        {
          request: {
            action: 'list',
            session: session,
            fromDate: dateTimeFrom,
            toDate: dateTimeTo,
            page: currentPage + 1,
            //filter
            ...(depositStatus && {byDepoStatus: depositStatus}),
            ...(merchantCd && {byMerchantCd: merchantCd}),
            ...(walletAccNo && {byWalletAccNo: walletAccNo}),
            ...(walletCd && {byWalletCd: walletCd}),
            ...(matchStatus && {byMatchStatus: matchStatus}),
            ...(searchTransaction && {byTransaction: searchTransaction}),
            ...(searchCustomer && {byCustomer: searchCustomer}),
            ...(searchAmount && {byAmount: searchAmount}),
            ...(callbackStatus && {byCallbackStatus: callbackStatus}),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let formData = response.data.result.depositRequestList
        setFormData(sortByDate(formData, 'depositDT'))
        setTotalCount(response.data.result.totalCount)

        setGetData(false)
        setDisabled(false)
        setLoading(false)
      })
      .catch(function (error) {})
  }, [currentPage, getData])

  function retrieveWalletAccNo(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          let walletAccNoList = UniqueWalletAccNo(result)

          setWalletAccNoList(sortByName(walletAccNoList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  function retrieveMerchant(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          let merchantCdList: any = Array.from(
            new Set(
              result
                .filter((item: any) => item.merchantCd !== '')
                .map((item: any) => {
                  return {
                    value: item.merchantCd,
                    label: item.merchantName + ' - ' + item.merchantCd,
                  }
                })
            )
          )

          setMerchantCdList(sortByName(merchantCdList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  //GET USER LIST
  useEffect(() => {
    retrieveWalletAccNo(SISPAY_API, '/wallet/list', 'wallet')
    retrieveMerchant(SISPAY_API, '/merchant/list', 'merchant')
  }, [])

  return (
    <div>
      <div className='card card-custom card-flush mb-5'>
        <div className='menu d-flex flex-column'>
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          </div>
          <div className='separator border-gray-200'></div>
          <div className='px-2 py-1'>
            <div className='mb-10'>
              <form onSubmit={formik.handleSubmit} className='m-5'>
                {' '}
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group Search */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Search Transaction
                      </label>
                      <input
                        placeholder='SEARCH Submitted Transaction ID, Merchant Transaction ID, Amount'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('searchTransaction')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.searchTransaction && formik.errors.searchTransaction,
                          },
                          {
                            'is-valid':
                              formik.touched.searchTransaction && !formik.errors.searchTransaction,
                          }
                        )}
                      />
                      {formik.touched.searchTransaction && formik.errors.searchTransaction && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.searchTransaction}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group Search */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Search Customer</label>
                      <input
                        placeholder='SEARCH Customer Code, Source Wallet Account'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('searchCustomer')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.searchCustomer && formik.errors.searchCustomer,
                          },
                          {
                            'is-valid':
                              formik.touched.searchCustomer && !formik.errors.searchCustomer,
                          }
                        )}
                      />
                      {formik.touched.searchCustomer && formik.errors.searchCustomer && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.searchCustomer}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group Search */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Amount</label>
                      <input
                        placeholder='SEARCH Amount'
                        type='number'
                        autoComplete='off'
                        {...formik.getFieldProps('searchAmount')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.searchAmount && formik.errors.searchAmount,
                          },
                          {
                            'is-valid': formik.touched.searchAmount && !formik.errors.searchAmount,
                          }
                        )}
                      />
                      {formik.touched.searchAmount && formik.errors.searchAmount && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.searchAmount}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group Merchant Code */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Merchant Code</label>
                      <Select
                        ref={selectedMerchantCd}
                        className='basic-single'
                        classNamePrefix='select'
                        options={merchantCdList}
                        styles={customStyles}
                        isClearable={true}
                        name='merchantCd'
                        value={
                          formik.values.merchantCd
                            ? {value: formik.values.merchantCd, label: formik.values.merchantCd}
                            : null
                        }
                        onChange={(e: any) =>
                          e !== null
                            ? formik.setFieldValue('merchantCd', e.value)
                            : formik.setFieldValue('merchantCd', '')
                        }
                      />
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {' '}
                    {/* begin::Form group Wallet Account Number */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Wallet Account Number
                      </label>
                      <Select
                        ref={selectedWalletAccNoRef}
                        styles={customStyles}
                        className='basic-single'
                        classNamePrefix='select'
                        name='walletAccNo'
                        options={walletAccNoList}
                        isClearable={true}
                        value={
                          formik.values.walletAccNo
                            ? {value: formik.values.walletAccNo, label: formik.values.walletAccNo}
                            : null
                        }
                        onChange={(e: any) =>
                          e !== null
                            ? formik.setFieldValue('walletAccNo', e.value)
                            : formik.setFieldValue('walletAccNo', '')
                        }
                      />
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group Wallet Code */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
                      <select
                        {...formik.getFieldProps('walletCd')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value=''>ALL</option>
                        <option value='NAGAD'>NAGAD</option>
                        <option value='BKASH'>BKASH</option>
                        <option value='ROCKET'>ROCKET</option>
                      </select>
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group Deposit Status */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Deposit Status</label>
                      <Select
                        ref={selectedDepositStatusRef}
                        isMulti
                        styles={customStyles}
                        name='matchStatus'
                        options={[
                          {value: 'INCOMPLETE', label: 'INCOMPLETE'},
                          {value: 'PENDING', label: 'PENDING'},
                          {value: 'SUCCESSFUL', label: 'SUCCESSFUL'},
                          {value: 'REJECTED', label: 'REJECTED'},
                          {value: 'FAILED', label: 'FAILED'},
                          {value: 'PENDING-RECHECK', label: 'PENDING-RECHECK'},
                        ]}
                        className='basic-multi-select'
                        classNamePrefix='select'
                        value={
                          filterOptions.depositStatus
                            ? filterOptions.depositStatus
                                .split('|')
                                .map((item: any) => ({value: item, label: item}))
                            : ''
                        }
                        isClearable={true}
                        onChange={handleSelectedDepositStatusChange}
                      />
                    </div>{' '}
                    {/* end::Form group */}
                  </div>

                  <div className='col'>
                    {/* begin::Form group Match Status */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Match Status</label>
                      <Select
                        ref={selectedMatchStatusRef}
                        isMulti
                        styles={customStyles}
                        name='matchStatus'
                        options={[
                          {value: 'UNMATCHED', label: 'Un-match'},
                          {value: 'AUTO-BOT', label: 'Auto-Bot'},
                          {value: 'AUTO-SMS', label: 'Auto-Sms'},
                          {value: 'MANUAL', label: 'Manual'},
                          {value: 'MANUAL-SMS', label: 'Manual SMS'},
                        ]}
                        className='basic-multi-select'
                        classNamePrefix='select'
                        value={
                          filterOptions.matchStatus
                            ? filterOptions.matchStatus
                                .split('|')
                                .map((item: any) => ({value: item, label: item}))
                            : ''
                        }
                        isClearable={true}
                        onChange={handleMatchStatusChange}
                      />
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group Callback Status */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Callback Status</label>
                      <select
                        {...formik.getFieldProps('callbackStatus')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value=''>ALL</option>
                        <option value='none'>NONE</option>
                        <option value='Success'>SUCCESS</option>
                        <option value='Sent'>SENT</option>
                      </select>
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group DateTime From */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>From</label>
                      <input
                        placeholder='Enter your start time'
                        type='date'
                        autoComplete='off'
                        {...formik.getFieldProps('dateTimeFrom')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                          },
                          {
                            'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                          }
                        )}
                      />
                      {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateTimeFrom}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group DateTime To */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>To</label>
                      <input
                        placeholder='Enter your end time'
                        type='date'
                        autoComplete='off'
                        {...formik.getFieldProps('dateTimeTo')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                          },
                          {
                            'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                          }
                        )}
                      />
                      {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateTimeTo}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                </div>
                <div className='row'>
                  <div className='col'></div>
                  <div className='col'></div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='text-center'>
                      <button
                        className='btn btn-lg btn-light w-100 mb-5'
                        disabled={disabled || downloadLoading}
                        // type='submit'
                        onClick={() => {
                          selectedMerchantCd.current.setValue('')
                          selectedMatchStatusRef.current.setValue('')
                          selectedWalletAccNoRef.current.setValue('')
                          selectedDepositStatusRef.current.setValue('')
                          setCurrentPage(1)
                          formik.resetForm({
                            values: {
                              searchTransaction: '',
                              searchCustomer: '',
                              searchAmount: '',
                              merchantCd: '',
                              walletAccNo: '',
                              walletCd: '',
                              matchStatus: '',
                              callbackStatus: '',
                              depositStatus: '',
                              dateTimeFrom: getCurrentDateInput(),
                              dateTimeTo: getCurrentDateInput(),
                            },
                          })
                        }}
                      >
                        {!loading && <span className='indicator-label'>Reset</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='text-center'>
                      <button
                        ref={depositFilterBtn}
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={
                          formik.isSubmitting || !formik.isValid || disabled || downloadLoading
                        }
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {/* end::Form group */}
                  </div>
                </div>
              </form>
            </div>
          </div>{' '}
        </div>
      </div>{' '}
      {Modal(loading)}
    </div>
  )
}
