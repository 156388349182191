import {useRef} from 'react'
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {useAuth} from '../../../auth'
import {customStyles} from '../../../components/CustomStyles'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {filterArrayByMultipleKeys} from '../../../components/Filter'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'
import {sortByName} from '../../../components/SortByName'
import {useAgentSummaryStore} from './AgentSummary_Store'

export function AgentSummary_Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData
  let creditReloadFilterBtn = props.creditReloadFilterBtn
  let setStartDate = props.setStartDate
  let setEndDate = props.setEndDate

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [agentSelectOptions, setAgentSelectOptions] = useState([])
  const [merchantCdList, setMerchantCdList] = useState([])

  //Refs
  const agentSelectRef = useRef<any>()
  const selectMerchantCdRef = useRef<any>(null)

  //Zustand Store
  const filterOptions = useAgentSummaryStore((state: any) => state.filterOptions)
  const getData = useAgentSummaryStore((state: any) => state.getData)
  const setFilterOptions = useAgentSummaryStore((state: any) => state.setFilterOptions)
  const setGetData = useAgentSummaryStore((state: any) => state.setGetData)

  //GET AGENT SELECT OPTIONS
  function getAgentSelectOptions() {
    let result
    axios
      .post(
        `${SISPAY_API}/user/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result.users
          let user: object
          let users: any = []

          for (let i = 0; i < result.length; i++) {
            if (result[i].userType == 'Agent') {
              user = {
                value: result[i].username,
                label: result[i].username,
              }
              users.push(user)
            }
          }

          users = Array.from(new Set(users))
          setAgentSelectOptions(sortByName(users, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  function getMerchantList() {
    axios
      .post(
        `${SISPAY_API}/merchant/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result.merchant
          // Extract merchantCd and form a new array of JSON objects
          let merchantCdList: any = Array.from(
            new Set(
              result
                .filter((item: any) => item.merchantCd !== '')
                .map((item: any) => {
                  return {
                    value: item.merchantCd,
                    label: item.merchantName + ' - ' + item.merchantCd,
                  }
                })
            )
          )
          setMerchantCdList(sortByName(merchantCdList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        // alert(error)
      })
  }

  useEffect(() => {
    getAgentSelectOptions()
    getMerchantList()
  }, [])

  const initialValues = {
    username: filterOptions.username ? String(filterOptions.username) : '',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
    walletCd: filterOptions.walletCd ? String(filterOptions.walletCd) : '',
    merchantCd: filterOptions.merchantCd ? String(filterOptions.merchantCd) : '',
    status: filterOptions.status ? String(filterOptions.status) : '',
  }

  const filterSchema = Yup.object().shape({
    username: Yup.string(),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)
      let username = values.username.trim()
      let dateTimeFrom = values.dateTimeFrom.trim()
      let dateTimeTo = values.dateTimeTo.trim()
      let walletCd = values.walletCd.trim()
      let merchantCd = values.merchantCd.trim()
      let status = values.status.trim()

      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/agent/summary/list`,
          {
            request: {
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
              session: session,
              ...(walletCd && {walletCd: walletCd}),
              ...(merchantCd && {merchantCd: merchantCd}),
              ...(status && {status: status}),
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let filteredFormData = filterArrayByMultipleKeys(
            response.data.result.agentSummary,
            ['agentUsername'],
            [username]
          )

          setStartDate(dateTimeFrom)
          setEndDate(dateTimeTo)

          setFormData(sortByName(filteredFormData, 'agentNickname'))

          setDisabled(false)
          setLoading(false)
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false) return

    setDisabled(true)
    setLoading(true)
    let username = formik.values.username.trim()
    let dateTimeFrom = formik.values.dateTimeFrom.trim()
    let dateTimeTo = formik.values.dateTimeTo.trim()
    let walletCd = formik.values.walletCd.trim()
    let merchantCd = formik.values.merchantCd.trim()
    let status = formik.values.status.trim()

    axios
      .post(
        `${SISPAY_API}/agent/summary/list`,
        {
          request: {
            fromDate: dateTimeFrom,
            toDate: dateTimeTo,
            session: session,
            ...(walletCd && {walletCd: walletCd}),
            ...(merchantCd && {merchantCd: merchantCd}),
            ...(status && {status: status}),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let filteredFormData = filterArrayByMultipleKeys(
          response.data.result.agentSummary,
          ['agentUsername'],
          [username]
        )

        setStartDate(dateTimeFrom)
        setEndDate(dateTimeTo)
        setGetData(false)
        setFormData(sortByName(filteredFormData, 'agentNickname'))

        setDisabled(false)
        setLoading(false)
      })
      .catch(function (error) {})
  }, [getData])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-2 py-1'>
          <div className=''>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='row'>
                <div className='col'>
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Agent</label>
                    <Select
                      ref={agentSelectRef}
                      className='basic-single'
                      classNamePrefix='select'
                      options={agentSelectOptions}
                      styles={customStyles}
                      isClearable={true}
                      name='username'
                      value={
                        formik.values.username
                          ? {value: formik.values.username, label: formik.values.username}
                          : null
                      }
                      onChange={(e: any) =>
                        e !== null
                          ? formik.setFieldValue('username', e.value)
                          : formik.setFieldValue('username', '')
                      }
                    />
                  </div>{' '}
                </div>
                <div className='col'>
                  <div
                    className='fv-row mb-5'
                    style={{
                      zIndex: 9999,
                    }}
                  >
                    <label className='form-label fw-bolder text-dark fs-6'>Merchant Code</label>
                    <Select
                      ref={selectMerchantCdRef}
                      className='basic-single'
                      classNamePrefix='select'
                      options={merchantCdList}
                      styles={customStyles}
                      isClearable={true}
                      name='merchantCd'
                      value={
                        formik.values.merchantCd
                          ? {value: formik.values.merchantCd, label: formik.values.merchantCd}
                          : null
                      }
                      onChange={(e: any) =>
                        e !== null
                          ? formik.setFieldValue('merchantCd', e.value)
                          : formik.setFieldValue('merchantCd', '')
                      }
                    />
                  </div>{' '}
                </div>
                <div className='col'>
                  {/* begin::Form group User type */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
                    <select
                      {...formik.getFieldProps('walletCd')}
                      className={clsx('form-control bg-transparent')}
                      data-placeholder=''
                    >
                      <option value=''>ALL</option>
                      <option value='BKASH'>BKASH</option>
                      <option value='NAGAD'>NAGAD</option>
                      <option value='ROCKET'>ROCKET</option>
                    </select>
                  </div>{' '}
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Status</label>
                    <select
                      {...formik.getFieldProps('status')}
                      className={clsx('form-control bg-transparent')}
                      data-placeholder=''
                    >
                      <option value=''>ALL</option>
                      <option value='ACTIVE'>ACTIVE</option>
                      <option value='INACTIVE'>INACTIVE</option>
                      <option value='DEACTIVE'>DE-ACTIVE</option>
                    </select>
                  </div>{' '}
                  {/* end::Form group */}
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>From</label>
                    <input
                      placeholder='Enter your start time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeFrom')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                        },
                        {
                          'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                        }
                      )}
                    />
                    {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeFrom}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                </div>
                <div className='col'>
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>To</label>
                    <input
                      placeholder='Enter your end time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeTo')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                        },
                        {
                          'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                        }
                      )}
                    />
                    {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeTo}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={() => {
                        agentSelectRef.current.setValue('')
                        formik.resetForm({
                          values: {
                            username: '',
                            dateTimeFrom: getCurrentDateInput(),
                            dateTimeTo: getCurrentDateInput(),
                            walletCd: '',
                            merchantCd: '',
                            status: '',
                          },
                        })
                      }}
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      ref={creditReloadFilterBtn}
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>{' '}
      </div>
    </div>
  )
}
