import axios from 'axios'
import {useRef} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'

export default function DepositRejectRequestModal(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  let currentRejectDeposit = props.currentRejectDeposit
  let setCurrentRejectDeposit = props.setCurrentRejectDeposit
  let depositFilterBtn = props.depositFilterBtn
  let setGetData = props.setGetData

  const inputRef = useRef<any>()

  function rejectDeposit() {
    if (currentRejectDeposit !== '') {
      axios
        .post(
          `${SISPAY_API}/cash/depositrequest/reject`,
          {
            request: {
              id: currentRejectDeposit,
              //approvalRejectionRemark: remark,
              approvalRejectionRemark: inputRef.current.value,
              session: session,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          depositFilterBtn.current.click()
          if (response.data.code == '200') {
            alert('Deposit Rejected!')
            setGetData(true)
          } else {
            alert(response.data.message)
            setGetData(true)
          }
        })
        .catch(function (error) {})
    } else {
      alert('An error happened.')
    }

    setCurrentRejectDeposit('')
    inputRef.current.value = ''
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_rejectDeposit'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>Reject Deposit ID: {currentRejectDeposit}</h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => {
                setCurrentRejectDeposit('')
                inputRef.current.value = ''
              }}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <h5>Remark:</h5>
            <textarea className='w-100 h-200px' ref={inputRef}></textarea>
          </div>
          <div className='modal-footer'>
            <button
              type='submit'
              className='btn btn-danger'
              data-bs-dismiss='modal'
              onClick={() => {
                rejectDeposit()
              }}
            >
              Reject Deposit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
