import React, {useState} from 'react'
import {
  Column,
  useTable,
  useResizeColumns,
  useFlexLayout,
  useFilters,
  usePagination,
} from 'react-table'
import {DepositPatchedFilter} from './components/Deposit-Patched-Filter'
import {Styles_NoAction} from '../../components/Table_Style_NoAction'

const DepositPatchedList: React.FC = () => {
  //States
  const [formData, setFormData] = useState([])

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  //Table::START
  interface Data {
    id: string
    patchType: string
    customerCd: string
    merchantCd: string
    walletCd: string
    walletAccNo: string
    amount: string
    depositDT: string
    depositStatus: string
    depositStatusUpdateDT: string
    submittedTxnId: string
    merchantTxnId: string
    matchStatus: string
    approvalRejectionRemark: string
    successBy: string
    completedDT: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
      },
      {
        Header: 'Patch Type',
        accessor: 'patchType',
        disableFilters: true,
      },
      {
        Header: 'Customer Code',
        accessor: 'customerCd',
        disableFilters: true,
      },
      {
        Header: 'Merchant Code',
        accessor: 'merchantCd',
        disableFilters: true,
      },
      {
        Header: 'Wallet Code',
        accessor: 'walletCd',
        disableFilters: true,
      },
      {
        Header: 'Wallet Account No',
        accessor: 'walletAccNo',
        disableFilters: true,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        disableFilters: true,
      },
      {
        Header: 'Deposit Date',
        accessor: (row: any) => {
          return row.depositDT.replace('T', ' ').replace(/\.\d+/, '')
        },
        disableFilters: true,
      },
      {
        Header: 'Deposit Status',
        accessor: 'depositStatus',
        disableFilters: true,
      },
      {
        Header: 'Deposit Status Update Date',
        accessor: 'depositStatusUpdateDT',
        disableFilters: true,
      },
      {
        Header: 'Submitted Txn ID',
        accessor: 'submittedTxnId',
        disableFilters: true,
      },
      {
        Header: 'Merchant Txn ID',
        accessor: 'merchantTxnId',
        disableFilters: true,
      },
      {
        Header: 'Match Status',
        accessor: 'matchStatus',
        disableFilters: true,
      },
      {
        Header: 'Approval Rejection Remark',
        accessor: 'approvalRejectionRemark',
        disableFilters: true,
      },
      {
        Header: 'Success By',
        accessor: 'successBy',
        disableFilters: true,
      },
      {
        Header: 'Completed Date',
        accessor: 'completedDT',
        disableFilters: true,
      },
    ],
    []
  )

  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFilters,
      usePagination,
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <DepositPatchedFilter setFormData={setFormData} />
      <Styles_NoAction>
        <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
      </Styles_NoAction>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={250}>250 per page</option>
          <option value={500}>500 per page</option>
        </select>
      </div>
      {/* Pagination END */}
    </div>
  )
}

export default DepositPatchedList
