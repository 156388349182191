import React, {useState, useEffect, useRef} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'
import Select from 'react-select'
import {useAuth} from '../../auth'
import {ErrorHandling} from '../../components/ErrorHandling'

const UserManagementPage_AddUser: React.FC = () => {
  const navigate = useNavigate()
  const {logout} = useAuth()
  const location: any = useLocation()

  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  const [loading, setLoading] = useState(false)

  console.log(location.state)

  let initialValues = {
    customerId: String(location.state.id),
    customerCd: String(location.state.customerCd),
    status: String(location.state.status),
  }

  const addUserSchema = Yup.object().shape({
    customerCd: Yup.string().required('Customer Code is required'),
    status: Yup.string().required('Status is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      let customerId = values.customerId
      let customerCd = values.customerCd
      let status = values.status

      try {
        axios
          .post(
            `${SISPAY_API}/blacklist/suspectedcustomer/update`,
            {
              request: {
                session: session,
                customerId: customerId,
                status: status,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            if (response.data.code == 200) {
              setLoading(false)
              navigate(-1)
              alert(response.data.message)
            } else {
              setLoading(false)
              ErrorHandling(response, logout)
            }
          })
          .catch(function (error) {
            alert(error)
          })
      } catch (error) {}
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Edit Customer Code Blacklist
        </span>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Customer Code</label>
            <input
              placeholder='Enter your customerCd'
              disabled
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('customerCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.customerCd && formik.errors.customerCd,
                },
                {
                  'is-valid': formik.touched.customerCd && !formik.errors.customerCd,
                }
              )}
            />
            {formik.touched.customerCd && formik.errors.customerCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customerCd}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Status</label>
            <select
              {...formik.getFieldProps('status')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.status && formik.errors.status,
                },
                {
                  'is-valid': formik.touched.status && !formik.errors.status,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='ACTIVE'>Active</option>
              <option value='INACTIVE'>Inactive</option>
              <option value='SUSPENDED'>Suspended</option>
            </select>
          </div>{' '}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>{' '}
        </form>
      </div>
    </div>
  )
}

export default UserManagementPage_AddUser
